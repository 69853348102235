:root {
    --main-blacktext-color: #000; /* Example: black text */
    --main-redtext-color: #063970; 
    --main-whitetext-color: #fff; 
    /* --main-bg-color: #ff3c5a; 
    --main-border-red-color: #ff3c5a;/* Example: light gray background */
    /* --main-border-color:  #fff;/* Example: black border */
    /* --button-text-color: #fff; /* Example: white text for buttons */
    /* --button-bg-color: #ff3c5a;; Example: black background for buttons   */
  } 
.fixed-bottom-navbar {
  display: none; /* Hide by default */
}

@media (max-width: 767px) { /* Apply styles for mobile devices */
  .fixed-bottom-navbar {
      position: fixed;
      bottom: 0;
      width: 100%;
      display: block; /* Show only for mobile view */
      z-index: 2; /* Adjust as needed */
      background-color: #ffffff; /* Optional: Change background color */
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  }

  .nav {
      justify-content: space-around;
      padding: 8px 0; /* Adjust padding as needed */
      width: 100%;
      display: flex;
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove default padding */
  }

  .nav-item {
      text-align: center;

  }

  .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #495057; /* Optional: Adjust link color */
      text-decoration: none !important; /* Optional: Remove default underline */
      padding: 8px 0; /* Add padding to the links */
  }

  .nav-link:hover .icon,
  .nav-link:hover .link-text {
      color:  var(--main-redtext-color) !important;
  };

  .icon {
    color: black; /* Initial color */
    transition: color 0.3s ease;
}

.icon:hover {
    color:  var(--main-redtext-color);/* Hover color */
}
.badgestyle:hover ~ .fa-icon
{
  color:   var(--main-redtext-color);
  transform: scale(1.1);
}

  .link-text {
      font-size: 0.65rem; /* Adjust font size for link text */
      margin-top: 2px; /* Optional: Adjust spacing between icon and text */
  }
  .badgestyle {
    position: absolute;
    top: -10px;
    right: -10px;
    transform: translate(50%, -50%);
    background-color: dark;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
  }
  
}
